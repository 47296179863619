/*
 *  moxie manager settings and buttons
 */

import $ from 'jquery'
import Vue from 'vue'

$( function() {
    /**
     * Moxie manager single image buttons
     */
    $('.moxman-image-section .moxman-image-add').click(function() {
        parent = $(this).closest('.moxman-image-section')
        moxman.browse({
            oninsert: function(args) {
                parent.find('.moxman-image-input').val(args.focusedFile.path)

                parent.find('.moxman-image img').attr('src', args.focusedFile.path)
                parent.find('.moxman-image').removeClass('hidden')

                parent.find('.moxman-image-remove').show()
                parent.find('.moxman-image-remove').removeClass('hidden')

                parent.find('.moxman-image-add').hide()
            },
            no_host: true,
            multiple: false
        })
    })

    $('.moxman-image-section .moxman-image-remove').click(function() {
        parent = $(this).closest('.moxman-image-section')

        parent.find('.moxman-image-input').val("")
        parent.find('.moxman-image').addClass('hidden')
        parent.find('.moxman-image-add').show().removeClass('hidden')

        $(this).hide()
    })

  /**
   * Moxie manager single pdf buttons
   */
  $('.moxman-pdf-section .moxman-pdf-add').click(function() {
    parent = $(this).closest('.moxman-pdf-section')
    moxman.browse({
      oninsert: function(args) {
        parent.find('.moxman-pdf-input').val(args.focusedFile.path)

        parent.find('.moxman-pdf a').html(args.focusedFile.path)
        parent.find('.moxman-pdf a').attr('href', args.focusedFile.path)
        parent.find('.moxman-pdf').removeClass('hidden')

        parent.find('.moxman-pdf-remove').show()
        parent.find('.moxman-pdf-remove').removeClass('hidden')

        parent.find('.moxman-pdf-add').hide()
      },
      no_host: true,
      multiple: false,
      extensions:'pdf'
    })
  })

  $('.moxman-pdf-section .moxman-pdf-remove').click(function() {
    parent = $(this).closest('.moxman-pdf-section')

    parent.find('.moxman-pdf-input').val("")
    parent.find('.moxman-pdf').addClass('hidden')
    parent.find('.moxman-pdf-add').show().removeClass('hidden')

    $(this).hide()
  })

})

Vue.component('moxiemultiselector', {
    props: ['oldImages', 'oldHighlightedImage'],

    data: function() {
        return {
            imagePaths: this.oldImages ? this.oldImages.split(',') : [],
            highlightedImage: this.oldHighlightedImage
        }
    },

    methods: {
        selectImages() {
            let imagePaths = this.imagePaths

            moxman.browse({
                oninsert: function(args) {
                    for(let $i = 0; $i < args.files.length; $i++) {
                        imagePaths.push(args.files[$i].path)
                    }
                },
                no_host: true,
                multiple: true
            })
        },

        setHighlightedImage( index ) {
            this.highlightedImage = index
        },

        deleteImage( index ) {
            if(index <= this.highlightedImage && this.highlightedImage > 0) {
                this.highlightedImage--;
            }

            this.imagePaths.splice(index, 1);
        }
    }
})

export default {
    el: '#app'
}