/**
 * Composer - content create form
 */
import $ from 'jquery'

export default {
  el: '#app',

  data: {
    id: null,
    type: '',

    title: '',
    oldTitle: '',
    slug: '',

    published: 1,
    wasAlreadyPublished: 0,

    templates: {},
    currentTemplate: '',
  },

  computed: {
    publishedLabel() {
      if (this.id) {
        return parseInt(this.published) === 1 ? 'Gepubliceerd' : 'Niet gepubliceerd'
      } else {
        return parseInt(this.published) === 1 ? 'Wordt gepubliceerd' : 'Wordt niet gepubliceerd'
      }
    },

    currentLayout() {
      if (this.templates[this.currentTemplate]) {
        return this.templates[this.currentTemplate].layout
      }

      return []
    }
  },

  methods: {
    /**
     * Retrieve theme settings (template list, blocknames and tinymce settings)
     */
    retrieveThemeSettings(type) {
      this.$http.get('/api/templates/' + type).then((response) => {
        this.templates = response.json()
      })
    },

    togglePublishedStatus() {
      // Invert the published status
      this.published = parseInt(this.published) === 1 ? 0 : 1;
    }
  },

  init() {

  },

  ready() {
    if (this.type !== 'area') {
      /**
       * Set a default template
       */
      if (!this.currentTemplate) {
        this.currentTemplate = 'page'
      }
    }

    /**
     * Retrieve theme settings: (template list after type has been set, blocknames and tinymce
     */
    this.retrieveThemeSettings(this.type)

    /**
     * Highlight image
     */
    $('.highlight_selectable_image .highlight-image-add').click(function() {
      parent = $(this).closest('.highlight_selectable_image')
      moxman.browse({
        oninsert: function(args) {
          parent.find('.highlight-image-input').val(args.focusedFile.path)
          parent.find('.highlight_img img').attr('src', args.focusedFile.path)
          parent.find('.highlight-image-remove').show()
          parent.find('.highlight-image-remove').removeClass('hidden');
          parent.find('.highlight_img').removeClass('hidden');
          parent.find('.highlight-image-add').hide();
        },
        no_host: true,
        multiple: false
      })
    })

    $('.highlight_selectable_image .highlight-image-remove').click(function() {
      parent = $(this).closest('.highlight_selectable_image')
      parent.find('.highlight-image-input:first').val("")
      parent.find('.highlight_img').addClass('hidden');
      parent.find('.highlight-image-add').show().removeClass('hidden')
      $(this).hide()
    })

    /**
     * Show the composer when all is done
     */
    $(() => {
      $('.content-settings').slideDown(100, () => {
        $('.content-editor').show()
      })
    })

    /**
     * Show confirmation if leaving without saving
     */
    $(this.$el).on('submit', (event) => {
      this.saveAndClose = true
    })

    $(window).on('beforeunload', (event) => {
      if(!this.saveAndClose) { return "Nieuwe content is nog niet aangemaakt" }
    })

    /**
     * Bind cmd+S to submitting the form
     */
    $(document).bind('keydown', function(e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault()
        $('#app').trigger('submit')
        return false
      }
    })
  }
}
