/**
 * jQuery plugins
 */
import $ from 'jquery'
import Vue from 'vue'
import select2 from 'select2'
import 'bootstrap/js/collapse'
import 'bootstrap/js/dropdown'
import 'bootstrap/js/modal'
import 'bootstrap-tagsinput/dist/bootstrap-tagsinput'

/**
 * Vue truncate filter
 */
Vue.filter('truncate', function (text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '…' : '')
})

/**
 * Custom Select2 directive for Vue
 */

Vue.directive('select', {
  twoWay: true,
  priority: 100,

  params: ['options'],

  bind() {
    let self = this

    $(this.el)
      .select2({ placeholder: 'Maak een keuze...' })
      .on('change', function () {
        self.set(this.value)
      })
  },

  update(value) {
    $(this.el).val(value).trigger('change')
  },

  unbind() {
    $(this.el).off().select2('destroy')
  }
})

/**
 * Regular Select2 boxes
 */
$(document).ready(function() {
  $('select.select2').select2()
  $('select.no-search').select2({ minimumResultsForSearch: -1 })
})
