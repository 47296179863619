/**
 * TinyMCE config
 */
export default {
      selector: '.tinymce',

      mode: 'none',
      theme: 'modern',
      width: '100%',
      height: 150,

      image_advtab: true,
      relative_urls: false,
      forced_root_block : '', 
      force_p_newlines : true,

      language : 'nl',
      language_url: '/valemus/js/tinymce_nl.js',

      toolbar: 'insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
      plugins : 'moxiemanager,link,code,image,autolink,lists,pagebreak,layer,table,insertdatetime,preview,media,searchreplace,print,contextmenu,paste,directionality,fullscreen,noneditable,visualchars,nonbreaking,template,wordcount,advlist,visualblocks',
      external_plugins: { 'moxiemanager': '/valemus/js/moxiemanager/plugin.min.js' },
      moxiemanager_title: 'Media'
}
