/**
 * Dashboard
 */
export default {
  el: '#app',

  data: {
    accessToken: '',
    tableID: '',
    labels: ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"],
    lastWeek: [0, 0, 0, 0, 0, 0, 0],
    thisWeek: [0, 0, 0, 0, 0, 0, 0],
    visitorChart: {}
  },

  methods: {
    updateChart() {
        this.visitorChart.data.datasets[0].data = this.thisWeek
        this.visitorChart.data.datasets[1].data = this.lastWeek
        this.visitorChart.update()
    }
  },

  ready() {
    // Load analytics
    (function(w,d,s,g,js,fjs){
      g=w.gapi||(w.gapi={});g.analytics={q:[],ready:function(cb){this.q.push(cb)}};
      js=d.createElement(s);fjs=d.getElementsByTagName(s)[0];
      js.src='https://apis.google.com/js/platform.js';
      fjs.parentNode.insertBefore(js,fjs);js.onload=function(){g.load('analytics')};
    }(window,document,'script'))
    
    gapi.analytics.ready(() => {
      // Authorize client
      gapi.analytics.auth.authorize({
        'serverAuth': {
          'access_token': this.accessToken
        }
      })

      // Pull in data
      let thisWeekReport = new gapi.analytics.report.Data({
        query: {
          'ids': this.tableID,
          'start-date': '7daysAgo',
          'end-date': 'today',
          'metrics': 'ga:users',
          'dimensions': 'ga:date'
        }
      })

      let lastWeekReport = new gapi.analytics.report.Data({
        query: {
          'ids': this.tableID,
          'start-date': '14daysAgo',
          'end-date': '7daysAgo',
          'metrics': 'ga:users',
          'dimensions': 'ga:date'
        }
      })

      thisWeekReport.on('success', (response) => {
        // Parse response
        this.thisWeek = response.rows.map((row) => { return +row[1] })
        this.updateChart()
      })

      lastWeekReport.on('success', (response) => {
        // Parse response
        this.lastWeek = response.rows.map((row) => { return +row[1] })
        this.updateChart()
      })

      thisWeekReport.execute()
      lastWeekReport.execute()
    })

    // Shift labels to current day
    let today = new Date()
    let weekShift = this.labels.splice(0, today.getDay())
    this.labels = this.labels.concat(weekShift)


    let ctx = document.getElementById("visitor-chart-container")
    this.visitorChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: this.labels,
            datasets: [{
                label: 'Bezoekers laatste 7 dagen',
                data: this.thisWeek,
                backgroundColor: 'rgba(157, 191, 61, 0.3)',
                borderColor: 'rgba(162, 185, 92, 1)',
                borderWidth: 3
            },
            {
                label: 'Vorige periode',
                data: this.lastWeek,
                backgroundColor: 'rgba(121, 132, 91, 0.2)',
                borderColor: 'rgba(203, 217, 159)',
                borderWidth: 0.5
            }]
        },
        options: {
            responsive: true,
            showTooltips: true,
            scales: {
                xAxes: [{
                    display: false
                }],
                yAxes: [{
                    display: false
                }]
            },
            legend: {
                labels: {
                    fontColor: '#999',
                    fontFamily: 'Montserrat'
                }
            }
        }
    })
  }
}
