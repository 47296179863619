/**
 * Valemus
 */
import './plugins'

import $ from 'jquery'
import Vue from 'vue'
import Raven from 'raven-js'
import RavenVue from 'raven-js/plugins/vue'
import VueResource from 'vue-resource'

import composer from './composer'
import composercreate from './composercreate'
import dashboard from './dashboard'
import itemlist from './itemlist'
import moduleManager from './moduleManager'
import menuManager from './menuManager'
import settings from './settings'
import moxie from './moxie'

let views = {composer, composercreate, dashboard, itemlist, moduleManager, menuManager, settings, moxie}
let component = views[$('#app').data('component')]

Vue.use(VueResource)

new Vue(component)


Raven
    .config('https://02157dbfceae4f65afec0a153a8fd468@sentry.io/186092')
    .addPlugin(RavenVue, Vue)
    .install();
