/**
 * Composer
 */
import $ from 'jquery'
import tinyMCEConfig from './tinyMCEConfig'

export default {
  el: '#app',

  data: {
    id: null,
    type: '',

    title: '',
    oldTitle: '',
    slug: '',

    published: 1,
    wasAlreadyPublished: 0,

    templates: {},
    oldCurrentTemplate: '',
    currentTemplate: '',
    currentSection: 0,

    defaultBlockNames: {
      text: 'Tekstblok',
      image: 'Afbeeldingblok',
      module: 'Module',
      area: 'Gebied'
    },
    customBlockNames: {},

    advancedSettingsExpanded: false,
    saveAndClose: false,

    content: [],
    tinyMCEConfigCustom: {},
    tinyMCEConfigDefault: {
      default: tinyMCEConfig
    }
  },

  computed: {
    tinyMCEConfig() {
      return Object.assign(this.tinyMCEConfigDefault, this.tinyMCEConfigCustom)
    },

    publishedLabel() {
      if (this.id) {
        return parseInt(this.published) === 1 ? 'Gepubliceerd' : 'Niet gepubliceerd'
      } else {
        return parseInt(this.published) === 1 ? 'Wordt gepubliceerd' : 'Wordt niet gepubliceerd'
      }
    },

    currentSectionName() {
      if (!this.templates[this.currentTemplate]) { return }
      return this.templates[this.currentTemplate].layout[this.currentSection].name
    },

    currentLayout() {
      if (this.templates[this.currentTemplate]) {
        return this.templates[this.currentTemplate].layout
      }

      return []
    },

    blockNames() {
      return Object.assign(this.customBlockNames, this.defaultBlockNames)
    },

    currentAllowedBlocks() {
      if (!this.templates[this.currentTemplate]) { return }
      if (!this.defaultBlockNames) { return }

      let blockNames = Object.assign(this.customBlockNames, this.defaultBlockNames)

      // get allowed blocktypes array
      let allowedBlocksTypes = this.templates[this.currentTemplate].layout[this.currentSection].allowedBlocks;

      // when allowedblocktypes is not specified, allow all blocks
      if (!allowedBlocksTypes) {
        return blockNames
      }

      // init empty obj
      let allowedBlocks = {}

      // iterate over the blocktypes array and grab the right blocks
      for(let i = 0; i < allowedBlocksTypes.length; i++) {
        allowedBlocks[allowedBlocksTypes[i]] = blockNames[allowedBlocksTypes[i]]
      }

      return allowedBlocks
    }
  },

  watch: {
    content() {
      tinyMCE.remove()
      this.initTinyMCE()

      if ($('select.select2').hasClass("select2-hidden-accessible")) {
        $('select.select2').select2('destroy');
        $('select.select2').off();
      }
      $('select.select2').select2()
      $('select.select2').on('select2:select', function (evt) {
        var element = evt.params.data.element;
        var $element = $(element);

        $element.detach();
        $(this).append($element);
        $(this).trigger("change");
      });
    },

    currentSection() {
      this.flashEditor()
    },

    currentTemplate() {
      this.currentSection = 0
      this.flashEditor()
    }
  },

  methods: {
    /**
     * Retrieve theme settings (template list, blocknames and tinymce settings)
     */
    retrieveThemeSettings(type) {
      this.$http.get('/api/theme/' + type).then((response) => {
        this.templates = response.json().templates
        this.customBlockNames = response.json().customblocks
        this.initContent()
        this.tinyMCEConfigCustom = response.json().tinymce
        this.initTinyMCE()
      })
    },

    initTinyMCE() {
      let vm = this
      Object.keys(this.tinyMCEConfig).forEach(function(config) {
        tinyMCE.init(vm.tinyMCEConfig[config])
      })
    },

    initContent() {
      /**
       * If there's still no content yet, start out with an empty text block
       */
      if (!this.content.length) {
        let block = Object.keys(this.currentAllowedBlocks)[0]
        this.addBlock(block)
      }
    },

    addBlock(type) {
      // Add the section if there is none already
      if(!this.content[this.currentSection]) {
        this.content.$set(this.currentSection, [])
      }

      // Add the block to the section
      this.content[this.currentSection].push({ type })
    },

    moveBlockUp(index, block) {
      this.moveBlock(index, block, true)
    },

    moveBlockDown(index, block) {
      this.moveBlock(index, block, false)
    },

    moveBlock(index, block, up) {
      let shift = up ? -1 : 1
      let old = this.content[this.currentSection][index + shift]

      this.content[this.currentSection].$set(index + shift, block)
      this.content[this.currentSection].$set(index, old)
    },

    removeBlock(block) {
      // Remove the block from the section
      this.content[this.currentSection].$remove(block)
    },

    flashEditor() {
      // Flash the editor briefly to indicate the interface changed
      $('.content-editor').hide(0, () => {
        $('.content-editor').fadeIn()
      })
    },

    setCurrentSection(index) {
      this.currentSection = index
    },

    setModule(name, displayName, section, block) {
      let newBlock = this.content[section][block]
      newBlock.module = name
      newBlock.moduleName = displayName
      this.content[section].$set(block, newBlock)
    },

    toggleAdvancedSettings() {
      // Invert status
      this.advancedSettingsExpanded = !this.advancedSettingsExpanded
      
      // Show a slide down animation if necessary
      if (this.advancedSettingsExpanded) {
        $('.content-advanced-settings').slideDown()
      }
    },

    togglePublishedStatus() {
      // Invert the published status
      this.published = parseInt(this.published) === 1 ? 0 : 1;
    },

    setImage(section,block) {
      moxman.browse({
        oninsert: (args) => {
          // set the url of the right content type thingy
          let updatedBlock = this.content[section][block]
          updatedBlock.src = args.focusedFile.path
          this.content[section].$set(block, updatedBlock)
        },
        no_host: true,
        multiple: false
      })
    },

    deleteImage(section,block) {
      let updatedBlock = this.content[section][block]
      updatedBlock.src = ""
      this.content[section].$set(block, updatedBlock)
    }
  },

  init() {

  },
  
  ready() {
    if (this.type !== 'area') {
      /**
       * Set a default template
       */
      if (!this.currentTemplate) {
        this.currentTemplate = 'page'
      }
    }

    /**
     * Retrieve theme settings: (template list after type has been set, blocknames and tinymce
     */
    this.retrieveThemeSettings(this.type)

    /**
     * Highlight image
     */
    $('.highlight_selectable_image .highlight-image-add').click(function() {
      parent = $(this).closest('.highlight_selectable_image')
      moxman.browse({
        oninsert: function(args) {
          parent.find('.highlight-image-input:first').val(args.focusedFile.path)
          parent.find('.highlight_img img:first').attr('src', args.focusedFile.path)
          parent.find('.highlight-image-remove').show()
          parent.find('.highlight-image-remove').removeClass('hidden')
          parent.find('.highlight_img').removeClass('hidden')
          parent.find('.highlight-image-add').hide()
        },
        no_host: true,
        multiple: false
      })
    })

    $('.highlight_selectable_image .highlight-image-remove').click(function() {
      parent = $(this).closest('.highlight_selectable_image')
      parent.find('.highlight-image-input:first').val("")
      parent.find('.highlight_img').addClass('hidden')
      parent.find('.highlight-image-add').show().removeClass('hidden')
      $(this).hide()
    })

    /**
     * Parse the loaded content if it's there
     */
    if (this.id) {
      let parsedContent = JSON.parse(this.content)
      this.content = parsedContent ? parsedContent : []
    }

    if (this.oldCurrentTemplate) {
      this.currentTemplate = this.oldCurrentTemplate
    }

    /**
     * Show the composer when all is done
     */
    $(() => {
      $('.content-settings').slideDown(100, () => {
        $('.content-editor').show()
      })
    })

    /**
     * Show confirmation if leaving without saving
     */
    $(this.$el).on('submit', (event) => {
      this.saveAndClose = true
    })

    $(window).on('beforeunload', (event) => {
      if(!this.saveAndClose) { return "Wijzigingen zijn nog niet opgeslagen" }
    })

    /**
     * Bind cmd+S to submitting the form
     */
    $(document).bind('keydown', function(e) {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault()
        $('#app').trigger('submit')
        return false
      }
    })
  }
}
