/**
 * Module uploading and deleting
 */
import $ from 'jquery'
import Vue from 'vue'
import 'nestable-fork'

// Register component
Vue.component('item', {
  template: '#item-template',
  props: {
    item: Object
  }
})

export default {
  el: '#app',

  data: {
    items: [],
    oldSerializedItemString: '',

    composerMode: 'new',
    composerTitle: '',
    composerType: 'page',
    composerURL: '',
    composerOpenInNewTab: false,

    currentItem: null,
    currentID: null,

    saveAndClose: true
  },

  computed: {
    serializedItemString() {
      return JSON.stringify(this.items)
    }
  },

  watch: {
    composerURL(val, oldVal) {
      if(!this.composerTitle || $('.contentSelector option').text().includes(this.composerTitle)) {
        this.composerTitle = $(`.contentSelector option[value="${val}"]`).text()
      }
    }
  },

  methods: {
    // Reset state to default
    newItem() {
      this.composerMode = 'new'
      this.composerOpenInNewTab = false
      this.composerTitle = ''
      this.composerType = 'page'
      this.composerURL = ''
    },

    // Set state to item props and set current item
    editItem(item, id) {
      this.composerMode = 'edit'
      this.composerOpenInNewTab = item.blank
      this.composerTitle = item.content
      this.composerType = item.type
      this.composerURL = item.url
      this.currentItem = item
      this.currentID = id
    },

    deleteItem() {
      // Delete the DOM node and let Nestable take over for this one
      $('#item_' + this.currentID).remove()

      // Sync up with Nestable
      this.items = $('#navigation-list').nestable('serialize')

      // Set unsaved status
      this.saveAndClose = false

      // Return to the main interface
      $('#composer').modal('hide')
    },

    // Either push a new object on the stack or change all props by reference
    handleComposer() {
      if(this.composerURL && this.composerTitle) {
        if (this.composerMode === 'new') {
          this.items.push({
            url: this.composerURL,
            type: this.composerType,
            content: this.composerTitle,
            blank: this.composerOpenInNewTab
          })
        } else {
          this.currentItem.url = this.composerURL
          this.currentItem.type = this.composerType
          this.currentItem.content = this.composerTitle
          this.currentItem.blank = this.composerOpenInNewTab
        }

        // Set unsaved status
        this.saveAndClose = false

        $('#composer').modal('hide')
      } else {
        // input modal/composer not complete
      }
    }
  },

  ready() {
    // If we picked up a previous configuration, parse that now
    if (this.oldSerializedItemString) {
      this.items = JSON.parse(this.oldSerializedItemString)
    }

    // Initialize the list
    $('#navigation-list').nestable({
      expandBtnHTML: '',
      collapseBtnHTML: '',
      includeContent: true,
      callback: (l, e) => {
        // Sync with Vue every time the list changes
        this.items = $('#navigation-list').nestable('serialize')
        // Set unsaved status
        this.saveAndClose = false
      }
    })

    // Show confirmation if leaving without saving
    $(this.$el).on('submit', (event) => {
      this.saveAndClose = true
    })

    $(window).on('beforeunload', (event) => {
      if(!this.saveAndClose) { return "Wijzigingen zijn nog niet opgeslagen" }
    })

    // Bind cmd+S to submitting the form
    $(document).bind('keydown', function(e) {
      // prevent submit of the form when pressing enter when the composer/modal is open, we handle the enter keydown on the modal element itself with vue
      if (e.keyCode === 13 && !$('#submitComposer').prop('disabled') ) {
        e.preventDefault()
      }

      if ((e.metaKey || e.ctrlKey) && e.keyCode === 83) {
        e.preventDefault()
        $('#app').trigger('submit')
        return false
      }
    })
  }
}
