/**
 * Module uploading and deleting
 */
import $ from 'jquery'

export default {
  el: '#app',

  data: {
    file: ''
  },

  methods: {
    openUploadDialog() {
      $('#moduleUploader').trigger('click')
    },

    uploadModule() {
      $('#app').submit()
    },

    confirmDelete(event) {
      if(!confirm("Weet je zeker dat je deze module wilt verwijderen?")) {
        event.preventDefault()
      }
    }
  }
}
