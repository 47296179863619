/**
 * Selectable itemlists with bulk editing
 */
import $ from 'jquery'

export default {
  el: '#app',

  data: {
    selectedIDs: []
  },

  computed: {
    itemCountLabel() {
      return this.selectedIDs.length === 1 ? '1 item geselecteerd' : `${this.selectedIDs.length} items geselecteerd`
    }
  },

  methods: {
    confirmDelete(event) {
      let message = 'Je staat op het punt '
      message += this.selectedIDs.length === 1 ? '1 item' : `${this.selectedIDs.length} items`
      message += ' permanent te verwijderen. Zeker weten?'

      if(!confirm(message)) {
        event.preventDefault()
      }
    },

    isSelected(id) {
      // Check if the ID is in the array of selected IDs
      return this.selectedIDs.indexOf(id) !== -1
    },

    toggleSelect(id) {
      // Get the index
      let index = this.selectedIDs.indexOf(id)
      
      // If this one exists, remove it
      if (index !== -1) {
        this.selectedIDs.splice(index, 1)
      } else {
        // Otherwise, push it onto the stack
        this.selectedIDs.push(id)
      }
    }
  },

  ready() {
    // Always reset the selection on load
    this.selectedIDs = []
  }
}
